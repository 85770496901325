import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';

/*
// scrollbar/height issue to investigate that is only visible on web
// The height being hardcoded prevents the extra scrollbar on intitial render
// however the height of the iframe can increase when selecting to use a new address
*/
const ExperiencePaymentsUI = ({ iFrameRef, initializeHtmlForm, experiencePaymentsURL }) => {
  return (
    <div data-testid="experience-payments-ui-container">
      <iframe
        ref={iFrameRef}
        title="payment-form"
        src={experiencePaymentsURL}
        onLoad={initializeHtmlForm}
        data-testid="payment-form-iframe"
        height="820px"
        width="100%"
        frameBorder="0"
        css={css`
          margin-left: -20px;
          margin-right: -20px;
        `}
      />
    </div>
  );
};

ExperiencePaymentsUI.propTypes = {
  iFrameRef: PropTypes.any.isRequired,
  initializeHtmlForm: PropTypes.any.isRequired,
  experiencePaymentsURL: PropTypes.string.isRequired,
};

export default ExperiencePaymentsUI;
