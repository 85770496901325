export const getExperiencePaymentsUIUrl = (env) => {
  switch (env) {
    case 'prd':
      return `https://payments-ui.sunrun.com`;
    case 'majstg':
      return `https://majstg-payments-ui.sunrun.com`;
    case 'devmaj':
    case 'local':
    default:
      return `https://devmaj-payments-ui.sunrundev.com`;
  }
};

export const MessageType = Object.freeze({
  INBOUND_INITIALIZE_AUTOPAY: 'SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_AUTOPAY',
  INBOUND_INITIALIZE_PAYMENT: 'SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_PAYMENT',
  OUTBOUND_AUTOPAY_SUCCESS: 'SUNRUN_PAYMENTS_UI_OUTBOUND_AUTOPAY_SUCCESS',
  OUTBOUND_PAYMENT_SUCCESS: 'SUNRUN_PAYMENTS_UI_OUTBOUND_PAYMENT_SUCCESS',
  OUTBOUND_SESSION_EXPIRED: 'SUNRUN_PAYMENTS_UI_OUTBOUND_SESSION_EXPIRED',
  OUTBOUND_WINDOW_RESIZE: 'SUNRUN_PAYMENTS_UI_OUTBOUND_WINDOW_RESIZE',
  OUTBOUND_CANCEL_PAYMENT: 'SUNRUN_PAYMENTS_UI_OUTBOUND_CANCEL_PAYMENT',
});

export const PaymentFormLabels = Object.freeze({
  PAYMENT_AMOUNT: 'Payment Amount',
  PAYMENT_METHOD: 'Payment Method',
  ENTER_PAYMENT_DETAILS: 'Enter payment details',
});

export const PaymentAmountRadioOptions = Object.freeze({
  FULL_ACCOUNT_BALANCE: 'Full account balance',
  OTHER_AMOUNT: 'Other amount',
});

export const OtherPaymentAmountError = Object.freeze({
  MINIMUM_REQUIRED: 'Payments must be at least $1.00',
  MAXIMUM_EXCEEDED: 'Payments cannot exceed the total balance due',
});

// method chosen by the user in the parent app
// https://github.com/SunRun/experience-payments-ui/blob/develop/src/types/external-message-types.ts
export const PayMethodEnum = Object.freeze({
  CARD: 'card',
  CHECKING: 'echeck',
  SAVINGS: 'savings',
});

// maps the values offered to our users in the UI to what cybersource expects
// cybersource expects 'card' or 'echeck'
// checking and savings are grouped into 'echeck' with 'echeck_account_type'
export const cybersourcePaymentMethodEnum = {
  [PayMethodEnum.CARD]: 'card',
  [PayMethodEnum.CHECKING]: 'echeck',
  [PayMethodEnum.SAVINGS]: 'echeck',
};

export const eCheckAccountType = {
  [PayMethodEnum.CHECKING]: 'C',
  [PayMethodEnum.SAVINGS]: 'S',
};

// label displaying to our customers / support reps
export const UIPaymentMethodEnum = {
  [PayMethodEnum.CARD]: 'Card',
  [PayMethodEnum.CHECKING]: 'Checking',
  [PayMethodEnum.SAVINGS]: 'Savings',
};
