import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { css } from '@emotion/core';
import { useHybridAuthToken } from '../../components/hybridAuthToken';
import useEnvConfig from '../../hooks/useEnvConfig';
import { MessageType, PayMethodEnum, PaymentAmountRadioOptions, getExperiencePaymentsUIUrl } from './paymentConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Box } from '@material-ui/core';
import PaymentMethodOptions from './PaymentMethodOptions';
import PaymentAmountOptions from './PaymentAmountOptions';
import ExperiencePaymentsUI from './ExperiencePaymentsUI';

// WIP V2 using experience-payments-ui
const OneTimePayment = ({
  userInfo,
  totalAmountDue,
  // specialInvoiceAmount,
  // referenceNumberPrefix,
  // isBillingEscalations,
}) => {
  const { paymentEnv } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const iFrameRef = useRef();
  const [formIsInitialized, setFormIsInitialized] = useState(false); // locking values / reset intitializing form
  const [hasPaymentAmountError, setHasPaymentAmountError] = useState(false);
  const [selectedPaymentAmountOption, setSelectedPaymentAmountOption] = useState(
    PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE,
  );
  const [otherPaymentAmount, setOtherPaymentAmount] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PayMethodEnum.CHECKING);

  const experiencePaymentsURL = getExperiencePaymentsUIUrl(paymentEnv);
  const paymentAmount =
    selectedPaymentAmountOption === PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE
      ? totalAmountDue
      : otherPaymentAmount;

  const sendDataToEmbeddedView = useCallback(
    (data) => {
      if (iFrameRef?.current && iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(data, experiencePaymentsURL);
      }
    },
    [iFrameRef, experiencePaymentsURL],
  );

  // setup initializing form
  const initializeHtmlForm = useCallback(() => {
    if (!userInfo) {
      return;
    }

    const payload = {
      amount: paymentAmount,
      authToken: hybridAuth,
      autopayEnrollmentEnabled: false, // not for autopay
      isIframe: true,
      payMethod: selectedPaymentMethod,
      showCancelPaymentButton: true,
      userInfo,
    };

    sendDataToEmbeddedView({
      payload,
      type: MessageType.INBOUND_INITIALIZE_PAYMENT,
    });
  }, [sendDataToEmbeddedView, paymentAmount, selectedPaymentMethod, userInfo, hybridAuth]);

  // we need to re-initialize if amount or method changes
  useEffect(() => {
    if (formIsInitialized) {
      setFormIsInitialized(false);
    }
    // we cannot have formIsInitialized in the dep array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentAmountOption, selectedPaymentMethod, otherPaymentAmount]);

  const handlePaymentAmountOptionsOnChange = (value) => {
    setSelectedPaymentAmountOption(value);
  };

  const handleOtherPaymentAmountOnChange = (value) => {
    setOtherPaymentAmount(Number(value));
  };

  const handlePaymentAmountValidation = (hasError) => {
    if (hasError && !hasPaymentAmountError) {
      setHasPaymentAmountError(true);
    }

    if (!hasError && hasPaymentAmountError) {
      setHasPaymentAmountError(false);
    }
  };

  const handlePaymentMethodOnChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const handleContinueOnPress = () => {
    setFormIsInitialized(true);
  };

  // const purchasedThru = userInfo?.purchasedThru || '';
  // const isCostco = purchasedThru.toLowerCase().includes('costco');

  if (!hybridAuth) return <LinearProgress />;

  return (
    <Box
      data-testid="one-time-payment-container"
      css={css`
        .MuiRadio-colorSecondary.Mui-checked {
          color: rgb(24, 30, 58);
        }
      `}
    >
      <PaymentAmountOptions
        totalAmountDue={totalAmountDue}
        selectedPaymentAmountOption={selectedPaymentAmountOption}
        handlePaymentAmountOptionsOnChange={handlePaymentAmountOptionsOnChange}
        handleOtherPaymentAmountOnChange={handleOtherPaymentAmountOnChange}
        handlePaymentAmountValidation={handlePaymentAmountValidation}
      />
      <PaymentMethodOptions
        selectedPaymentMethod={selectedPaymentMethod}
        handlePaymentMethodOnChange={handlePaymentMethodOnChange}
        isCardEligible={true}
      />
      <Button
        data-testid="continue-button"
        variant="contained"
        disabled={hasPaymentAmountError || formIsInitialized}
        onClick={handleContinueOnPress}
        css={css`
          color: rgb(255, 255, 255);
          background-color: rgb(24, 30, 58);
          text-transform: none;
          &:hover {
            background-color: rgba(24, 30, 58, 0.9);
          }
        `}
      >
        Continue
      </Button>
      {formIsInitialized && (
        <ExperiencePaymentsUI
          iFrameRef={iFrameRef}
          initializeHtmlForm={initializeHtmlForm}
          experiencePaymentsURL={`${experiencePaymentsURL}/payment`}
        />
      )}
    </Box>
  );
};

OneTimePayment.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    purchasedThru: PropTypes.string,
  }),
  // specialInvoiceAmount: PropTypes.number,
  // referenceNumberPrefix: PropTypes.string,
  totalAmountDue: PropTypes.number,
  // isBillingEscalations: PropTypes.bool,
};

export default OneTimePayment;
