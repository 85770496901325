import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { css } from '@emotion/core';
import { useHybridAuthToken } from '../../components/hybridAuthToken';
import useEnvConfig from '../../hooks/useEnvConfig';
import { MessageType, PayMethodEnum, getExperiencePaymentsUIUrl, PaymentFormLabels } from './paymentConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Box } from '@material-ui/core';
import PaymentMethodOptions from './PaymentMethodOptions';
import ExperiencePaymentsUI from './ExperiencePaymentsUI';

const AutopayEnrollment = ({ userInfo }) => {
  const { paymentEnv } = useEnvConfig();
  const hybridAuth = useHybridAuthToken();
  const iFrameRef = useRef();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PayMethodEnum.CHECKING);
  const [formIsInitialized, setFormIsInitialized] = useState(false); // locking values / reset intitializing form
  const allowCreditCardAutoPay = userInfo.autopayByCreditCardEligible;
  const experiencePaymentsURL = getExperiencePaymentsUIUrl(paymentEnv);

  const sendDataToEmbeddedView = useCallback(
    (data) => {
      if (iFrameRef?.current && iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage(data, experiencePaymentsURL);
      }
    },
    [iFrameRef, experiencePaymentsURL],
  );

  // setup initializing form
  const initializeHtmlForm = useCallback(() => {
    if (!userInfo) {
      return;
    }

    const payload = {
      amount: 0,
      authToken: hybridAuth,
      isIframe: true,
      payMethod: selectedPaymentMethod,
      showCancelPaymentButton: true,
      userInfo,
    };

    sendDataToEmbeddedView({
      payload,
      type: MessageType.INBOUND_INITIALIZE_AUTOPAY,
    });
  }, [sendDataToEmbeddedView, selectedPaymentMethod, userInfo, hybridAuth]);

  // we need to re-initialize if amount or method changes
  useEffect(() => {
    if (formIsInitialized) {
      setFormIsInitialized(false);
    }
    // we cannot have formIsInitialized in the dep array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  const handlePaymentMethodOnChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const handleContinueOnPress = () => {
    setFormIsInitialized(true);
  };

  if (!hybridAuth) return <LinearProgress />;

  return (
    <Box
      data-testid="autopay-enrollment-container"
      css={css`
        .MuiRadio-colorSecondary.Mui-checked {
          color: rgb(24, 30, 58);
        }
      `}
    >
      <PaymentMethodOptions
        selectedPaymentMethod={selectedPaymentMethod}
        handlePaymentMethodOnChange={handlePaymentMethodOnChange}
        isCardEligible={allowCreditCardAutoPay}
      />
      <Button
        data-testid="proceed-to-enter-payment-details"
        variant="contained"
        disabled={formIsInitialized}
        onClick={handleContinueOnPress}
        css={css`
          color: rgb(255, 255, 255);
          background-color: rgb(24, 30, 58);
          text-transform: none;
          &:hover {
            background-color: rgba(24, 30, 58, 0.9);
          }
        `}
      >
        {PaymentFormLabels.ENTER_PAYMENT_DETAILS}
      </Button>
      {formIsInitialized && (
        <ExperiencePaymentsUI
          iFrameRef={iFrameRef}
          initializeHtmlForm={initializeHtmlForm}
          experiencePaymentsURL={`${experiencePaymentsURL}/autopay`}
        />
      )}
    </Box>
  );
};

AutopayEnrollment.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    totalAmountDue: PropTypes.string,
    autopayByCreditCardEligible: PropTypes.bool,
  }),
};

export default AutopayEnrollment;
